.noty_layout { 
    position: absolute;
    bottom: 0;
    width: 100% !important;
    max-width: none !important;
    left:0 !important;
    font-size: 18px;
  }

  .noty_bar {
    margin: 0 !important;
  }

  .noty_body {
    padding:15px 20px;
  }

  .noty_type__success {
    background-color: #4FC575 !important;
    border: 1px solid #4FC575 !important;
    color:#fff !important;
  }
  .noty_type__error {
    background-color: #ec536c !important;
    border: 1px solid #ec536c !important;
    color:#fff !important;
  }
  .noty_type__warning {
    background-color: #f5b225 !important;
    border: 1px solid #f5b225 !important;
    color:#fff !important;
  }
  .noty_type__info {
    background-color: #059cc6 !important;
    border: 1px solid #059cc6 !important;
    color:#fff !important;
  }

  .noty_progressbar {
    height: 4px !important;
    background-color: #646464 !important;
    opacity: 0.6 !important;
  }
