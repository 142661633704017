@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;900&display=swap');


html {
    font-size: 14px
}

.rotate{
  animation: rotation infinite 1s linear;
}

@keyframes rotation{
 from{
   transform:rotate(0deg);
 }
 
 to{
   transform:rotate(360deg);
 }
}

@layer components {
    .input-light {
        @apply w-full border text-sm bg-gray-100 border-gray-100 outline-none px-3 py-2 rounded-lg 
      focus:bg-gray-200
      focus:border-gray-200
        disabled:opacity-25 
        disabled:pointer-events-none 
        transition;
    }
    .input-error {
        @apply bg-red-100 border-red-100 placeholder-red-400 focus:bg-red-200 focus:border-red-200
        focus:placeholder-red-500;
    }

    .input-light-readonly {
      @apply w-full border text-sm bg-gray-100 border-gray-100 outline-none px-3 py-2 rounded-lg 
      h-9
      whitespace-nowrap 
      overflow-ellipsis 
      overflow-hidden
      focus:bg-gray-200
      focus:border-gray-200
        disabled:opacity-25 
        disabled:pointer-events-none 
        transition;
    }
    .badge-warning {
      @apply bg-yellow-100 px-3 py-1 rounded-lg text-yellow-500 font-semibold whitespace-nowrap text-xs
    }
    .badge-success {
      @apply bg-green-100 px-3 py-1 rounded-lg text-green-500 font-semibold whitespace-nowrap text-xs
    }
    .badge-info {
      @apply bg-blue-100 px-3 py-1 rounded-lg text-blue-500 font-semibold whitespace-nowrap text-xs
    }
    .badge-danger {
      @apply bg-red-100 px-3 py-1 rounded-lg text-red-500 font-semibold whitespace-nowrap text-xs
    }
    .badge-gray {
      @apply bg-gray-100 px-3 py-1 rounded-lg text-gray-500 font-semibold whitespace-nowrap text-xs
    }

    .sidebar-menu .active a {
      @apply text-purple-800 bg-white
    }

    .sidebar-mobile {
      @apply fixed h-full top-0 left-0 hidden shadow-md
    }
}